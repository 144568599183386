import React, { useState } from "react";
import { signOut } from "firebase/auth";
import ButtonSM from "../../components/ButtonSM";
import { auth } from "../../firebase/firebaseconfig";
import { Offcanvas } from "react-bootstrap";
import "./menu_header.css";
import { Link } from "react-router-dom";

function MenuHeader({ activeSection, navigateToSection }) {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const handleClose = () => setIsContentVisible(false);
  const handleShow = () => setIsContentVisible(true);

  const handleSignOut = async () => {
    signOut(auth).catch((error) => {
      console.error("Error signing out:", error.message);
    });
  };

  return (
    <header className="menu_page-header">
      <div className="menu_header-container">
        <ButtonSM onClick={handleShow}></ButtonSM>
        <Offcanvas className="main_menu-content" show={isContentVisible} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Title</Offcanvas.Title>
            <Offcanvas.Body>
                <Link></Link>
            </Offcanvas.Body>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <button onClick={handleSignOut}>Sign Out</button>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="menu_brand">
          <img src="/assets/GuruLogo.png" alt="LMKGuru Logo" />
        </div>
      </div>
    </header>
  );
}

export default MenuHeader;

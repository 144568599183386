import React from 'react';
import FeasibilityIndicator from './FeasibilityIndicator';
import FeasibilityContent from './FeasibilityContent';
import FeasibilityStudy from './feasibilityStudy';
import { Carousel } from 'react-bootstrap';
import './feasibility.css';



function FeasibilityContainer({ventureId}) {
    return (
        <main className="feasibilitycontainer">
        <Carousel interval={null} className='feasibilityslider'>
            <Carousel.Item>
                <FeasibilityStudy ventureId={ventureId} />
            </Carousel.Item>
            <Carousel.Item>
                <FeasibilityContent ventureId={ventureId} />
            </Carousel.Item>
            <Carousel.Item>
                <FeasibilityIndicator ventureId={ventureId} />
            </Carousel.Item>
        </Carousel>
        </main>
    );
};

export default FeasibilityContainer;



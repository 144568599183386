import "./main_menu.css";
import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebaseconfig";
import { collection, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {useAuth} from "../util/AuthProvider";
import VentureDropdown from "./venture_dropdown/venture_dropdown";
import VentureButton from "./VentureButton/ventureButton";
import GetStartedButton from "./GetStartedButton/GetStartedButton";
import { motion } from "framer-motion";
import MenuHeader from "./menu_header/menu_header";

function MainMenu() {
  const [ventures, setVentures] = useState([]);
  const {userId} = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState("untitled");
  const [nameSelection, setNameSelection] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);
  const [nameFeedback, setNameFeedback] = useState("");



  useEffect(() => {
    const ventureRef = collection(db, 'users', userId, 'ventures');

    const unsubscribe = onSnapshot(ventureRef, (snapshot) => {
      const updatedVentures = snapshot.docs.map(doc => doc.data()); 
      setVentures(updatedVentures);
    }, (error) => {
      console.error("Error getting documents: ", error);
    });
  
    return () => unsubscribe(); // Cleanup on unmount
  }, [userId]);

  const handleNameChange = (e) => {
    const isValidName = (name) => {
      // only allow letters, numbers, and -_
      return /^[a-zA-Z0-9-_]+$/.test(name);
    };
    const userInput = e;
    setName(userInput);
    
    if (!isValidName(userInput)) {
      setIsNameValid(false);
      setNameFeedback('Name contains invalid characters. Only letters, numbers, -, and _ are allowed.');
    } else {
      setIsNameValid(true);
      setNameFeedback(''); // Clear feedback if the name is valid
    }
  };
  

  return (
    <main className="main_menu">
      <MenuHeader/>
      <div className="venture_bank_main">
        <div className="container_body">
          <h1 style={{marginTop:"20px"}}>Venture Bank</h1>
          <h2>Select your previous ventures</h2>
          <div className="venture_bank_buttons">
            {ventures.map((venture) => (
                <div key={venture.id}>
                  <VentureButton onClick={() => navigate(`venture/${venture.id}`)}>
                    {venture.name}
                  </VentureButton>
                  <div className="venture_dropdown">
                    <VentureDropdown ventureId={venture.id} />
                  </div>
                  
                </div>
            ))}
          </div>
        </div>
      </div>
      <div className="start_venture_main">
        <div className="venture_container_body">
          {nameSelection ? (
            
              <motion.div
                style={{ transform: "scale(1.2)" }}
                initial={{ y: -200, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="create_venture_container"
              >
                <img className="name_back_arrow" onClick={() => setNameSelection(false)} src="/entry_images/arrow_left.svg" alt="" />
                <h1 style={{ color: "var(--Pearl-container-color)" }}>Enter Project Name</h1>
                {!isNameValid && <p className="error">{nameFeedback}</p>}
                <input
                  className="name_input"
                  type="text"
                  placeholder="Project Name"
                  onChange={(e) => {
                    handleNameChange(e.target.value);
                  }}
                />
                <GetStartedButton style={{ marginTop: "30px" }} onClick={() => navigate(`ideate/${name}`)} disable={!isNameValid} />
              </motion.div>
         
          ) : (
            <motion.div
              style={{ transform: "scale(1.2)" }}
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="create_venture_container"
            >
              <h1 style={{ color: "var(--Pearl-container-color)" }}>Create Venture</h1>
              <p>Are you ready to fully realize your startup ideas? Start a chat with Guru and get started!</p>
              <GetStartedButton style={{ marginTop: "30px" }} onClick={() => setNameSelection(true)} />
            </motion.div>
          )}
        </div>
      </div>
      <div className="faq_main">
        <div className="container_body">
          <h1 style={{marginTop:"20px"}}>FAQ</h1>
          <h2>How do I start A venture analysis?</h2>
          <p>Click on "Create New Venture" under "Start Venture" and enter a name to begin the conversation and analysis with Guru.</p>
          <h2>How do I access my previous venture anaylses?</h2>
          <p>Click on the venture you would like to access under "Venture Bank".</p>
          <h2>How does Guru Work?</h2>
          <p>
            Guru is a trained and engineered AI model that is tailored towards startups and new business ideas. Simply tell Guru the information it
            asks for and it will create a unique analysis based on your proposed idea.
          </p>
        </div>
      </div>
      <div className="feedback_main">
        <div className="container_body">
          <h1 style={{marginTop:"20px"}}>Feedback</h1>
          <h2>How can we improve?</h2>
          <p>We are always looking to improve our service. If you have any feedback, please let us know by sending an email to</p>
          <p>Add contact form here</p>
        </div>
      </div>
    </main>
  );
}

export default MainMenu;

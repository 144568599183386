import React, { useEffect, useState } from "react";
import ButtonSM from "../components/ButtonSM";
import { auth } from "../firebase/firebaseconfig";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import {useAuth} from "../util/AuthProvider";
import "./login.css";
import { motion } from "framer-motion";
import SignUpPage from "./signup";

function LoginPage() {
  const [password_hidden, setPassword_hidden] = useState(true);
  const [show_eye_icon, setShow_eye_icon] = useState(false);
  const [signupButton, setSignupButton] = useState(true);

  const toggleSignupButton = () => {
    setSignupButton(!signupButton);
  };

  const handleEyeIconMouseDown = (event) => {
    event.preventDefault(); // Prevents the icon from taking focus away from the input
  };

  const eyeIconVariants = {
    visible: { opacity: 1, transition: { duration: 0.3 } },
    hidden: { opacity: 0, transition: { duration: 0.3 } },
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const {loading, user} = useAuth();

  useEffect(() => {
    if (user && !loading) {
      navigate("/menu");
    }
  }, [loading, user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
      signInWithEmailAndPassword(auth, email, password).then( () => {
        if (!loading) {
          navigate("/menu");
        }
        
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error Signing In: ", errorCode, errorMessage);
      });
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider).then(async () => {
        if (!loading) {
          navigate("/menu");
        }
      }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error("Error Signing In: ", errorCode, errorMessage);
    });
  };

  <h1 className="login_header">Login matteomastrogiacomo@gmail.com Welcome134</h1>;
  return signupButton ? (
    <main className="login_main">
      <div className="login_body">
        <form className="login_form" onSubmit={handleLogin}>
          <h1 className="login_header">Login</h1>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
             
            }}
          />
          <input
            type={password_hidden ? "password" : "text"}
            placeholder="Password"
            value={password}
            onFocus={() => setShow_eye_icon(true)}
            onBlur={() => setShow_eye_icon(false)}
            onChange={(e) => {
              setPassword(e.target.value);
             
            }}
          />
          {show_eye_icon && (
            <motion.img
              className="eye_icon"
              src="/entry_images/eye_icon.svg"
              onClick={() => setPassword_hidden(!password_hidden)}
              onMouseDown={handleEyeIconMouseDown}
              alt=""
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={eyeIconVariants}
            />
          )}

          <ButtonSM style={{ width: "100%" }} type="submit">
            Sign In
          </ButtonSM>
        </form>
        <div className="google_button" onClick={handleGoogleSignIn}>
          <img src="/entry_images/google_icon.svg" alt="" />
          <p style={{ color: "black" }}>Sign in with Google</p>
        </div>

        <p style={{ color: "#fff8e8", paddingLeft: "0" }}>
          Don't have an account?{" "}
          <Link style={{ color: "#A776EC" }} onClick={() => setSignupButton(false)}>
            Sign Up
          </Link>
        </p>
      </div>
    </main>
  ) : (
    <SignUpPage toggleSignupButton={toggleSignupButton}/>
  );
}

export default LoginPage;

import React, { useState, useEffect } from 'react';
import * as THREE from 'three';
import { VANTA } from 'vanta';
import LoginPage from './login';
import SignUpPage from './signup';

function Entry() {
  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    VANTA.NET({
      el: "#vanta-bg",
      THREE: THREE,
      mouseControls: false,
      touchControls: true,
      gyroControls: false,
      mouseControls: true,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
    });
  }, []);

  return (
    <div id="vanta-bg" style={{ width: '100vw', height: '100vh' }}>
      {isSignUp ? <SignUpPage toggle={() => setIsSignUp(false)} /> : <LoginPage toggle={() => setIsSignUp(true)} />}
    </div>
  );
}

export default Entry;

import React, { useState, useEffect} from 'react';
import {useRequest} from '../../../util/RequestProvider';
import {useAuth} from '../../../util/AuthProvider';
import './feasibility.css';


function FeasibilityIndicator({ventureId}) {
    const [feasibilityScore, setFeasibilityScore] = useState(0);
    const [rotationAngle, setRotationAngle] = useState(45);
    const {sendRequest} = useRequest();
    const {token} = useAuth();

  useEffect(() => {
    const getContent = async () => {
      let score;
      sendRequest({
        url: 'https://api-mp6fvdmhza-uc.a.run.app/feasibility/getScore',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: { ventureId: ventureId, },
        token: token
      }).then((response) => {
        score = response.score;
        setFeasibilityScore(score);
      }).catch((error) => {
        console.error("Error while requesting score: ", error);
      });
      
    };
    getContent();
  }, [ventureId, token, sendRequest])
  
    
    useEffect(() => {
        setRotationAngle(45 + (feasibilityScore * 18));

    }, [feasibilityScore]);

    return (
        <main className='feasibilityScale'>
            <h1>Feasibility Rating</h1>
            <h2>This section of Feasibility presents a Feasibility Rating conducted by Guru on your venture. The rating is based on a number of factors that are important to the success of a new business. Currently WIP</h2>
            <svg className="feasibilityindicator" id="meter" >
                {/* Meter Outline */}
                <circle id="outline_curves" r="150" cx="50%" cy="50%" stroke="black" strokeWidth="67"
                strokeDasharray="471, 943" fill="none" />
                {/* Low Range Zone */}
                <circle id="low" r="150" cx="50%" cy="50%" stroke="red" strokeWidth="60" strokeDasharray="471, 943"
                fill="none" />
                {/* Average Range Zone */}
                <circle id="avg" r="150" cx="50%" cy="50%" stroke="yellow" strokeWidth="60" strokeDasharray="314, 943"
                fill="none" />
                {/* High Range Zone */}
                <circle id="high" r="150" cx="50%" cy="50%" stroke="green" strokeWidth="60" strokeDasharray="157, 943"
                fill="none" />
                {/* Outline Ends */}
                <circle id="outline_ends" r="150" cx="50%" cy="50%" stroke="black" strokeWidth="66"
                strokeDasharray="2, 469" fill="none" />
            </svg>
            <img alt="Needle Indicator" id="meter_needle" src="/assets/gauge-needle.svg"
                style={{
                    transform: `rotate(${rotationAngle}deg)`,
                }}
            />
        </main>
    );
}

export default FeasibilityIndicator;

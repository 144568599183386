import React, { useEffect, useState } from "react";
import MapCompetitors from "./MapCompetitors";
import {useRequest} from "../../../util/RequestProvider";
import {useAuth} from "../../../util/AuthProvider";
import "./mapcontainer.css";

function MapContainer() {
  const [competitors, setCompetitors] = useState([]);
  const {sendRequest} = useRequest();
  const {token} = useAuth();

  useEffect(() => {
    const fetch = async () => {
      try {
          const response = await sendRequest({url: "https://api-mp6fvdmhza-uc.a.run.app/competitors", token: token});
          setCompetitors(response.competitors);
      } catch (error) {
        console.error('Error fetching competitors', error);
      }
    }
    return () => fetch();
  },[token, sendRequest] );

    return(
    <div className="map-competitors">
      <MapCompetitors competitors={competitors}/>
    </div>
    );
}

export default MapContainer
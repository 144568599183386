import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateVenture from './createventure/createVenture';
import Interface from '../interface/interface';
import MainMenu from './main_menu';

function Menu() {

  return (
      <Routes>
        <Route path="/" element={<MainMenu/>}/>
        <Route path="ideate/:name?" element={<CreateVenture />} />
        <Route path="venture/:ventureId?" element={<Interface />} />
      </Routes>
  );
}

export default Menu;
import React from "react";
import "./ventureButton.css";

function VentureButton({ children, onClick, style }) {
  return (
    <div>
      <button className="venturebutton" style = {style} onClick={onClick}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {children}
      </button>
    </div>
  );
}

export default VentureButton;
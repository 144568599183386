import React, {useState, useEffect} from "react";
import "./feasibility.css";
import { Overlay, Popover } from "react-bootstrap";
import SpacebarButton from "../SpacebarButton/spacebarButton";
import {useRequest} from "../../../util/RequestProvider";
import {useAuth} from "../../../util/AuthProvider";


function FeasibilityStudy({ventureId}) {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverContent, setPopoverContent] = useState('');
  const [popoverTitle, setPopoverTitle] = useState('');
  const [target, setTarget] = useState(null);
  const [content, setContent] = useState({
    marketAnalysis: "",
    productserviceFeasibility: "",
    financialFeasibility: "",
    technicalFeasibility: "",
    operationalFeasibility: "",
    growthPotential: "",
  });
  const {sendRequest} = useRequest();
  const {token} = useAuth();

  useEffect(() => {
    const getContent = async () => {
      let StudyContent;
      sendRequest({
        url: 'https://api-mp6fvdmhza-uc.a.run.app/feasibility/getStudy',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: { ventureId: ventureId},
        token: token
      }).then((response) => {
        StudyContent = response;
        setContent({
          marketAnalysis: StudyContent.marketanalysis,
          productserviceFeasibility: StudyContent.productserviceanalysis,
          financialFeasibility: StudyContent.financialanalysis,
          technicalFeasibility: StudyContent.technicalanalysis,
          operationalFeasibility: StudyContent.operationalanalysis,
          growthPotential: StudyContent.growthPotential,
        });
      }).catch((error) => {
        console.error("Error while requesting SWOT content: ", error);
      });
      
    };
    getContent();
  }, [ventureId, token, sendRequest])

  
  const handleClick = (event, type) => {
    setTarget(event.target); 
    const display = Object.values(content[type]).join(". ") + ".";
    setPopoverContent(display);
    setPopoverTitle(event.target.textContent);
    setShowPopover(!showPopover);
  };

  return (
        <main className="feasibilitystudy">
            <h1>Feasibility Study</h1>
            <h2>This section of Feasibility presents a Feasibility Study conducted by Guru on your venture. Each area in the study represents key factors in a new business.</h2>
            <div className="buttonwrapperStudy" >
          <SpacebarButton onClick={(e) => handleClick(e, 'marketAnalysis')}>Market Analysis</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'productserviceFeasibility')}>Product/Service Feasibility</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'financialFeasibility')}>Financial Feasibility</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'technicalFeasibility')}>Technical Feasibility</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'operationalFeasibility')}>Operational Feasibility</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'growthPotential')}>Growth Potential</SpacebarButton>
        </div>
        <Overlay show={showPopover} target={target} placement="bottom">
        <Popover id="popover-contained">
          <Popover.Header as="h3">{popoverTitle}</Popover.Header>
          <Popover.Body>{popoverContent}</Popover.Body>
        </Popover>
        </Overlay>
        </main>
    );
}

export default FeasibilityStudy;
import React, { useState } from "react";
import { auth } from "../firebase/firebaseconfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import ButtonSM from "../components/ButtonSM";

import "./signup.css";
import { motion } from "framer-motion";

function SignUpPage({toggleSignupButton}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();


  const handleSignUp = async (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password).then(() => {
      alert("Account Created Successfully!");
      navigate("/");
    }).catch((error) => {
      alert("Invalid Credentials, please try again!");
      console.log(error);
    });
  };

  const [show_eye_icon, setShow_eye_icon] = useState(false);
  const [password_hidden, setPassword_hidden] = useState(true);

  const handleEyeIconMouseDown = (event) => {
    event.preventDefault(); // Prevents the icon from taking focus away from the input
  };

  const eyeIconVariants = {
    visible: { opacity: 1, transition: { duration: 0.3 } },
    hidden: { opacity: 0, transition: { duration: 0.3 } },
  };

  return (
    <main className="signup_main">
      <div className="signup_body">
        <h1 className="signup_header">Create an Account</h1>
        <form className="signup_form" onSubmit={handleSignUp}>
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input
            type={password_hidden ? "password" : "text"}
            placeholder="Password"
            value={password}
            onFocus={() => setShow_eye_icon(true)}
            onBlur={() => setShow_eye_icon(false)}
            onChange={(e) => {
              setPassword(e.target.value);
             
            }}
          />
          {show_eye_icon && (
            <motion.img
              className="eye_icon2"
              src="/entry_images/eye_icon.svg"
              onClick={() => setPassword_hidden(!password_hidden)}
              onMouseDown={handleEyeIconMouseDown}
              alt=""
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={eyeIconVariants}
            />
          )}
          <ButtonSM style={{ width: "100%" }} type="submit">
            Sign Up
          </ButtonSM>
          <div className="back_arrow" >
            <img onClick={toggleSignupButton} src="/entry_images/arrow_left.svg" alt="" />
          </div>
        </form>
       
      </div>
    </main>
  );
};

export default SignUpPage;

import React from "react";
import "./spacebarButton.css";

function SpacebarButton({ children, onClick }) {
  return (
    <div>
      <button className="spacebarbutton" onClick={onClick}>
        <span>{children}</span>
      </button>
    </div>
  );
}

export default SpacebarButton;
import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View, Feature, Overlay } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { extend } from 'ol/extent';
import { useGeographic } from 'ol/proj';

function MapInitalizer({competitors}) {
  const mapRef = useRef(null);
  const popupRef = useRef(null);
  const [map, setMap] = useState(null);
  useGeographic();

  

  useEffect(() => {
    const initMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
    });

    setMap(initMap);
  },[]);

  useEffect(() => {
    if (map && competitors) {
      if (!mapRef.current || competitors.length === 0) return;
      const popup = new Overlay({
        element: popupRef.current,
        positioning: 'bottom-center',
      });
      map.addOverlay(popup);

      const markerSource = new VectorSource();
      const markerLayer = new VectorLayer({ source: markerSource });
      map.addLayer(markerLayer);

      // Create and add markers
      let extent = null;
      competitors.forEach((loc) => {
        const point = new Point([loc.lng, loc.lat]);
        const feature = new Feature(point);
        feature.setProperties(loc);
        markerSource.addFeature(feature);

        if (extent) {
          extent = extend(extent, point.getExtent());
        } else {
          extent = point.getExtent();
        }
      });

      // Center the view to fit all markers
      if (extent) {
        map.getView().fit(extent, { padding: [50, 50, 50, 50] });
      }

      // Popup logic
      map.on('click', (event) => {
        const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
        if (feature) {
          const coordinates = feature.getGeometry().getCoordinates();
          const competitor = feature.getProperties();
          popup.setPosition(coordinates);
          popupRef.current.innerHTML = "<div>" + competitor.name + ": " + competitor.description + "</div>";
        } else {
          popup.setPosition(undefined);
        }
      });
    }

  }, [map, competitors]);

  return (
    <>
      <div ref={mapRef} id='map' ></div>
      <div id='popup' ref={popupRef}></div>
    </>
  );
};

export default MapInitalizer;

import React, { useState, useEffect, useRef } from 'react';
import "./venture_dropdown.css"
import { Modal, Form, Button } from "react-bootstrap";
import { useAuth } from "../../util/AuthProvider";
import { useRequest } from '../../util/RequestProvider';

function VentureButton({ventureId}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newName, setNewName] = useState('');
  const {token} = useAuth();
  const {sendRequest} = useRequest();


  // This function toggles the dropdown's open state
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // This will also reset the burger icon back to its default state
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const editVentureName = async () => {
    sendRequest({
      url: "https://api-mp6fvdmhza-uc.a.run.app/ventures/changeName",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { ventureId: ventureId, name: newName },
      token: token,
    }).then(() => {
    setShowEditModal(false);
    }).catch((error) => {
      console.error("Error changing name: ", error);
    });
  };

  const deleteVenture = async () => {
    sendRequest({
      url: "https://api-mp6fvdmhza-uc.a.run.app/ventures/deleteVenture",
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: { ventureId: ventureId },
      token: token,
    }).then(() => {
      setShowDeleteModal(false);
    }).catch((error) => {
      console.error("Error deleting venture: ", error);
    });
  };
  

  return (
    <>
      <label className="popup" ref={dropdownRef} >
        <input type="checkbox" checked={isOpen}  onChange={toggleDropdown}/>
        <div className="burger" tabIndex="0">
          <span></span>
          <span></span>
          <span></span>
        </div>
        {isOpen && (
        <nav className="popup-window">
          <ul>
              <li>
              <button onClick={handleEditClick}>
                <svg strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" stroke="currentColor" fill="none" viewBox="0 0 24 24" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
                </svg>
                <span>Edit</span>
              </button>
            </li>
            <li>
              <button onClick={handleDeleteClick}>
                <svg strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" stroke="currentColor" fill="none" viewBox="0 0 24 24" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
                  <line y2="18" x2="6" y1="6" x1="18"></line>
                  <line y2="18" x2="18" y1="6" x1="6"></line>
                </svg>
                <span>Delete</span>
              </button>
            </li>
          </ul>
        </nav>
        )}
      </label>
      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Venture Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>New Name</Form.Label>
              <Form.Control type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>Close</Button>
          <Button variant="primary" onClick={editVentureName}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this venture?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={deleteVenture}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VentureButton;
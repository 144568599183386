import { initializeApp } from "firebase/app";
import { getAuth, browserSessionPersistence, setPersistence } from "firebase/auth";
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyDYnVDZdiNxb5vTNmkLGLqwru6vflyn_-Y",
    authDomain: "lmkguru.firebaseapp.com",
    databaseURL: "https://lmkguru-default-rtdb.firebaseio.com",
    projectId: "lmkguru",
    storageBucket: "lmkguru.appspot.com",
    messagingSenderId: "990915695302",
    appId: "1:990915695302:web:9adca99d2f70089658fdc7",
    measurementId: "G-TZ5TVW9H57",
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
setPersistence(auth, browserSessionPersistence);
const db = getFirestore(firebase);

export { firebase, auth, db };

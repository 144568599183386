import React, { useEffect, useState } from "react";
import { useRequest } from "../../util/RequestProvider";
import { useAuth } from "../../util/AuthProvider";
import "./createVenture.css";
import { useParams } from "react-router-dom";
import MenuHeader from "../menu_header/menu_header";

function CreateVenture() {
  const [ventureId, setVentureId] = useState("");
  const { sendRequest } = useRequest();
  const { userId, token } = useAuth();
  const { name } = useParams();
  const [responses, setResponses] = useState({
    name: name,
    businessAge: "",
    location: "",
    industry: "",
    productOrService: "",
    ventureIdea: "",
});

  useEffect(() => {
    const handleCreate = () => {
      sendRequest({
        url: "https://api-mp6fvdmhza-uc.a.run.app/ventures/createVenture",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: { name: name },
        token: token,
      }).then((result) => {
        const ventureId = result.id;
        setVentureId(ventureId);
        }).catch((error) => {
        console.error("Error Creating Venture: ", error);
      });
    };
    handleCreate();
  }, [token, sendRequest, name]);


const handleChange = (e) => {
    setResponses({
        ...responses,
        [e.target.name]: e.target.value,
    });
};

const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form responses: ', responses);
    sendRequest({
      url: "https://api-mp6fvdmhza-uc.a.run.app/ventures/startModelV2",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: {
        name: name, 
        industry: responses.industry, 
        productOrService: responses.productOrService, 
        location: responses.location, 
        ventureIdea: responses.ventureIdea, 
        businessAge: responses.businessAge
      },
      token: token,
    }).then(() => {
      alert("Your venture has been submitted!")
    }).catch((error) => {
      console.log("Error  Submitting Form: ", error);
    });
};
  return (
    <main className="create_venture_main">
      <MenuHeader/>
        <div className="survey_form_border">
          <form onSubmit={handleSubmit} className="survey-form">
            <div className="question">
              <p>Is Your Business:</p>
              <div className="options">
                <label>
                  <input type="radio" name="businessAge" value="New Venture" onChange={handleChange} checked={responses.businessAge === "New Venture"} />
                  A New Venture
                </label>
                <label>
                  <input type="radio" name="businessAge" value="Established Business" onChange={handleChange} checked={responses.businessAge === "Established Business"} />
                  An Established Business
                </label>
              </div>
            </div>
            <div className="question">
              <p>Location:</p>
              <textarea
                className="input_box"
                name="location"
                value={responses.location}
                onChange={handleChange}
                placeholder="Where are you located? (City, State, Country)"
              />
            </div>
            <div className="question">
              <p>Industry/Sector:</p>
              <textarea
                className="input_box"
                name="industry"
                value={responses.industry}
                onChange={handleChange}
                placeholder="What industry are you in or entering? Include the sector too. For example, Information Technology/Software Development."
              />
            </div>
            <div className="question">
              <p>Product/Service:</p>
              <textarea
                className="input_box"
                name="productOrService"
                value={responses.productOrService}
                onChange={handleChange}
                placeholder="Give a 1-2 sentence description of the product or service that you offer/intend to offer."
              />
            </div>
            <div className="question">
              <p>Business Description:</p>
              <textarea
                className="input_box"
                name="ventureIdea"
                value={responses.ventureIdea}
                onChange={handleChange}
                placeholder="Give a 1-2 sentence description of the your business concept/idea."
              />
            </div>
            {/* Submission button */}
            <button className="venture_submit_button" type="submit">Submit</button>
          </form>
        </div>
    </main>
  );
}

/*
    <div className="ventureChatContainer">
      <div className="messageContainer">
        {messages.map((message, index) => (
          <div key={index} className="message">
            <div className="messageRole">{message.role === "user" ? "You" : "Guru"}</div>
            <MessageAnimation style={{ color: "#fff" }} text={message.message} speed={10} />
          </div>
        ))}
        <div ref={messagesbottomRef} />
        {loading && <div className="loadingMessage">Guru is typing...</div>}
      </div>
      <form className="inputContainer" onSubmit={handleSubmitai}>
        <input
          className="textInput"
          type="text"
          value={messageinput}
          onChange={(e) => setMessageInput(e.target.value)}
          disabled={isExecuting || !awaitingInput || isCompleted}
          placeholder="Type your message here..."
        />
        <button className="sendButton" type="submit" disabled={isExecuting || !awaitingInput || isCompleted}>
          Send
        </button>
      </form>
    </div>
    */

export default CreateVenture;

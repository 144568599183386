import React, { useState, useEffect} from 'react';
import {useRequest} from '../../../util/RequestProvider';
import {useAuth} from '../../../util/AuthProvider';
import SpacebarButton from '../SpacebarButton/spacebarButton';
import { Overlay, Popover } from 'react-bootstrap'
import './costoutlook.css';


function CostOutlook({ventureId}) {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverContent, setPopoverContent] = useState('');
  const [popoverTitle, setPopoverTitle] = useState('');
  const [target, setTarget] = useState(null);
  const [content, setContent] = useState({
    initialCapital: "",
    fundingTradeoffs: "",
    operatingExpenses: "",
  });
  const {sendRequest} = useRequest();
  const {token} = useAuth();
  useEffect(() => {
    const getContent = async () => {
      let CostOutlookContent;
      sendRequest({
        url: 'https://api-mp6fvdmhza-uc.a.run.app/finances/getCostOutlook',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: { ventureId: ventureId, },
        token: token
      }).then((response) => {
        CostOutlookContent = response;
        setContent({
          initialCapital: CostOutlookContent.initial_capital,
          fundingTradeoffs: CostOutlookContent.funding_tradeoffs, 
          operatingExpenses: CostOutlookContent.operating_expenses, 
        });
      }).catch((error) => {
        console.error("Error while requesting CostOutlook content: ", error);
      });

    };
    getContent();
  }, [ventureId, token, sendRequest]);

  const handleClick = (event, type) => {
    setTarget(event.target); 
    setPopoverContent(content[type]);
    setPopoverTitle(event.target.textContent);
    setShowPopover(!showPopover);
  };

  return (
    <main className="costoutlook">
      <h1>Cost Outlook</h1>
      <h2>Understand the Initial Costs and Projected Expenses related to your venture</h2>
      <div className="descriptionwrapper">
        <p>Initial Captial looks into the necessary resources required to start your venture. Moreover, it describes what equipment, tools, machines, and/or other pertainent things that you will need.</p>
        <p>Funding Tradeoffs looks into the funding costs you will incur. Your tradeoffs will vary depending on the complexity and scale of your venture.</p>
        <p>Operating Expenses looks into the reoccuring costs you will incur once your venture is theoretically up and running.</p>
      </div>
        <div className="buttonwrapper" >
          <SpacebarButton onClick={(e) => handleClick(e, 'initialCapital')}>Initial Capital</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'fundingTradeoffs')}>Funding Tradeoffs</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'operatingExpenses')}>Operating Expenses</SpacebarButton>
        </div>
        <Overlay
        show={showPopover}
        target={target}
        placement="bottom"
      >
        <Popover id="popover-contained">
          <Popover.Header as="h3">{popoverTitle}</Popover.Header>
          <Popover.Body>{popoverContent}</Popover.Body>
        </Popover>
        </Overlay>
    </main>
  );
}

export default CostOutlook;


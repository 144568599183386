import React, { useState, useEffect } from 'react';
import {useRequest} from '../../../util/RequestProvider';
import {useAuth} from '../../../util/AuthProvider';
import { Overlay, Popover } from 'react-bootstrap';
import SpacebarButton from '../SpacebarButton/spacebarButton';
import './idealCustomer.css';

function IdealCustomer({ventureId}) {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverContent, setPopoverContent] = useState('');
  const [popoverTitle, setPopoverTitle] = useState('');
  const [target, setTarget] = useState(null);
	const [content, setContent] = useState({
			Characteristics: "",
			Psychographics: "",
			Preferences: "",
			purchasing_Behaviors: "",
			Locations: "",
	})
	const {sendRequest} = useRequest();
  const {token} = useAuth();

	useEffect(() => {
    const getContent = async () => {
      let CustomerContent;
      sendRequest({
        url: 'https://api-mp6fvdmhza-uc.a.run.app/psychographics/getConsumer',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: { ventureId: ventureId, },
        token: token
      }).then((response) => {
        CustomerContent = response;
				setContent({
					Characteristics: CustomerContent.customer_characteristics,
					Psychographics: CustomerContent.psychographics,
					Preferences: CustomerContent.preferences,
					Purchasing_Behaviors: CustomerContent.purchasing_behaviors,
					Locations: CustomerContent.locations,
				});
      }).catch((error) => {
        console.error("Error while requesting SWOT content: ", error);
      });

    };
    getContent();
  }, [ventureId, token, sendRequest])

  
  const handleClick = (event, type) => {
    setTarget(event.target); 
    setPopoverContent(content[type]);
    setPopoverTitle(event.target.textContent);
    setShowPopover(!showPopover);
  };
  

	return (
		<main className="idealcustomercontainer">
			<h1>Know Your Customer</h1>
      <h2>Understand the key aspects of your target customer.</h2>
      <div className="descriptionwrapper">
        <p>Your customer's characteristics include aspects like age, gender, education, and income. It defines who they are.</p>
        <p>Your customer's psychographics represents their interests, opinions, and more which define their world view. For example, an eco-conscious person would be more likely to support eco-friendly products.</p>
        <p>Your customer's preferences in regards to the products and/or services they purchase. For example, a prefernce in vegan-friendly products.</p>
        <p>Your customer's purchasing behaviors reflect their actions when deciding why and when to purchase. For example, a customer who is loyal to a brand and purchases from them frequently.</p>
        <p>Your customer's geographic location are hubs or centers where they reside. </p>
      </div>
      <div className="buttonwrapper" >
          <SpacebarButton onClick={(e) => handleClick(e, 'Characteristics')}>Characteristics</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'Psychographics')}>Psychographics</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'Preferences')}>Preferences</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'Behaviors')}>Purchasing Behaviors</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'Locations')}>Location</SpacebarButton>
        </div>
        <Overlay show={showPopover} target={target} placement="bottom">
        <Popover id="popover-contained">
          <Popover.Header as="h3">{popoverTitle}</Popover.Header>
          <Popover.Body>{popoverContent}</Popover.Body>
        </Popover>
        </Overlay>
		</main>
	);
}

export default IdealCustomer;
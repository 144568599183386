import React, { useState, useEffect } from 'react';
import { useRequest } from '../../../util/RequestProvider';
import { useAuth } from '../../../util/AuthProvider';
import "./ChatFeed.css";

function ChatFeed({ventureId}) {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const chatEndRef = React.useRef(null);
    const {sendRequest} = useRequest();
    const {token} = useAuth();
    
    useEffect(() => {
        const getContent = async () => {
          let ChatFeed;
          sendRequest({
            url: 'https://api-mp6fvdmhza-uc.a.run.app/chat/listMessages',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: { ventureId: ventureId },
            token: token
          }).then((response) => {
            ChatFeed = response;
            setMessages(ChatFeed);
          }).catch((error) => {
            console.error("Error while requesting SWOT content: ", error);
          });
          
        };
        getContent();
      }, [ventureId, token, sendRequest])

    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }    

    const handleSubmit = async () => {
        if (inputValue.trim() === "") return;

        // Add user's message to the chat
        setMessages(prevMessages => [...prevMessages, { content: inputValue, role: "user" }]);
        setLoading(true);
        setInputValue("");

        const timeoutId = setTimeout(() => {
            setMessages(prevMessages => [...prevMessages, { content: "Error Timed Out. Try Again.", role: "assistant" }]);
            setLoading(false);
        }, 10000);
        
        sendRequest({
            url: 'https://api-mp6fvdmhza-uc.a.run.app/chat/addMessage',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: { userMessage: inputValue, ventureId: ventureId, },
            token: token
        }).then((response) =>{
            console.log(response);
            setMessages(prevMessages => [...prevMessages, { content: response.newResponse, role: "assistant"}]);
            setLoading(false);
            clearTimeout(timeoutId);
        }).catch((error) => {
            console.error('Error processing the message:', error);
        });
        
    }

    const scrollToBottom = () => {
        if (chatEndRef.current) {
            const scrollHeight = chatEndRef.current.scrollHeight;
            const height = chatEndRef.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            chatEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }

    return (
        <div className='chat-input-container'>
            <div className="chatfeed" ref={chatEndRef}>
                {messages.map((message, index) => {
                    if (message.role === 'assistant') {
                        return (
                            <div key={index} className="message-bubble Guru">
                                Guru: {message.content}
                             </div>
                        );
                    } else {
                        return (
                            <div key={index} className="message-bubble User">
                                User: {message.content}
                            </div>
                        );
                    }
                })}
                {loading && <div className="loading">Waiting for Guru's response...</div>}
            </div>
            <div className="input-container">
                <div id='textInput'>
                    <input 
                        type="text" 
                        value={inputValue} 
                        onChange={handleInputChange} 
                        onKeyDown={handleKeyDown}
                        placeholder="Let Guru Know..." 
                    />
                </div>
                    {//<button onClick={handleSubmit}>Send</button>
                    }
            </div>
        </div>
    );
}

export default ChatFeed;

import React, { useEffect, useState } from 'react';
import MapInitalizer from './MapInitializer';

function MapCompetitors({competitors}) {
  const [staticPoint, setStaticPoint] = useState({}) // the user's position

  
  useEffect(() => {
    // Calculate distance - this is a simplistic calculation and might need adjustments
    competitors.forEach(competitor => {
      competitor.distance = Math.sqrt(
        Math.pow(competitor.lat - staticPoint.lat, 2) +
        Math.pow(competitor.lng - staticPoint.lng, 2)
      );
    });

    // Sort competitors by distance
    competitors.sort((a, b) => a.distance - b.distance);

    setStaticPoint({lat: 0, lng: 0});
  }, [competitors])


  return (
    <>
      <MapInitalizer competitors={competitors}/>
      <div className='competitors-list'>
        <ul className='competitors'>
          {competitors.map((competitor, index) => (
            <li key={index}>
              {index + 1}. {competitor.name} 
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
    
export default MapCompetitors;

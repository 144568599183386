import React from "react";
import "./ButtonSM.css";

function ButtonSM({ onClick, className, children, ...otherProps}) {

	return(
		<button className={`buttonSM ${className}`} type="button" onClick={onClick} {...otherProps}>
			{children}
		</button>
	); 

}

export default ButtonSM;
import React, {useContext, createContext, useState, useEffect} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../firebase/firebaseconfig";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => { 
      if (user) {
        setUser(user);
        setUserId(user.uid);
        setLoading(false);
        const token = await user.getIdToken(); 
        setToken(token);
      } else {
        setUser(null);
        setUserId("");
        setLoading(false);
        setToken("");
      }
    });
    return () => unsubscribe();  
  }, []);

  const authState = {
    user,
    userId,
    loading,
    token
  };

  return (
    <AuthContext.Provider value={authState}>
      {!authState.loading && children}
    </AuthContext.Provider>
  );
}

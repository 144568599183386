import React, { useState } from "react";
import { signOut } from "firebase/auth";
import ButtonSM from "../../../components/ButtonSM";
import { auth } from "../../../firebase/firebaseconfig";
import { Offcanvas } from "react-bootstrap";
import "./Header.css";

function Header({ activeSection, navigateToSection }) {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const handleClose = () => setIsContentVisible(false);
  const handleShow = () => setIsContentVisible(true);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  const sections = [
    { key: "chatContainer", name: "Continue Chatting" },
    { key: "costOutlook", name: "Cost Outlook" },
    { key: "feasibilityContainer", name: "Feasibility Section" },
    { key: "idealCustomer", name: "Know Your Customer" },
    { key: "mapContainer", name: "Map of Competitors" },
  ];

  return (
    <header className="page-header">
      <div className="header-container">
        <ButtonSM onClick={handleShow} />
        <div className="brand">
          <img src="/assets/GuruLogo.png" alt="LMKGuru Logo" />
        </div>
        <nav className="nav-routes">
          {sections.map((section) => (
            <h1
              key={section.key}
              className={`nav-route ${activeSection === section.key ? "active" : ""}`}
              onClick={() => navigateToSection(section.key)}
            >
              {section.name}
            </h1>
          ))}
        </nav>

        <Offcanvas className="menu-content" show={isContentVisible} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <button onClick={handleSignOut}>Sign Out</button>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </header>
  );
}

export default Header;

import React from "react";
import ReactDOM from "react-dom/client";
import "./root.css";
import App from "./app";
import { AuthProvider } from "./util/AuthProvider";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
		<AuthProvider>
			<App/>
		</AuthProvider>
);
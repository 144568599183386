import React, {useState, useRef, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import ChatContainer from './components/ChatFeed/ChatContainer';
import CostOutlook from './components/CostOutlook/CostOutlook';
import FeasibilityContainer from './components/FeasibilityContainer/FeasibilityContainer';
import MapContainer from './components/MapContainer/MapContainer';
import IdealCustomer from './components/IdealCustomer/IdealCustomer';
import NetworkContainer from './components/NetworkContainer';
import Header from './components/Header/Header';
import "./interface.css";

function Interface() { 
  const { ventureId } = useParams();
  const [activeSection, setActiveSection] = useState('');
  const [observerActive, setObserverActive] = useState(true);

  const sectionRefs = {
    chatContainer: useRef(null),
    costOutlook: useRef(null),
    feasibilityContainer: useRef(null),
    idealCustomer: useRef(null),
    mapContainer: useRef(null),
    networkContainer: useRef(null),
  };

  useEffect(() => {
    if (!observerActive) return;
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });
  
    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });
  
    // Clean up function to unobserve when component unmounts or observerActive changes
    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, [observerActive]); // Dependency array includes observerActive to re-run effect when it changes
  

  // Function to scroll to a section. This will be passed to the Header component.
  const navigateToSection = (sectionKey) => {
    setObserverActive(false); // Disable observer before scrolling
    setActiveSection(sectionKey); // Manually set active section
    sectionRefs[sectionKey]?.current?.scrollIntoView({ behavior: 'smooth' });
  
    setTimeout(() => {
      setObserverActive(true); // Re-enable observer after scrolling
    }, 1000); // Adjust delay as needed to match scroll duration
  };

  // Pass 'activeSection' and 'navigateToSection' to the Header component
  return (
    <main className="interface_main">
      <Header activeSection={activeSection} navigateToSection={navigateToSection} />
      <div className="center-container">
        <div id="chatContainer" ref={sectionRefs.chatContainer}><ChatContainer ventureId={ventureId}/></div>
        <div id="costOutlook" ref={sectionRefs.costOutlook}><CostOutlook ventureId={ventureId} /></div>
        <div id="feasibilityContainer" ref={sectionRefs.feasibilityContainer}> <FeasibilityContainer ventureId={ventureId} /></div>
        <div id="idealCustomer" ref={sectionRefs.idealCustomer}><IdealCustomer ventureId={ventureId} /></div>
        <div id="mapContainer" ref={sectionRefs.mapContainer}><MapContainer ventureId={ventureId} /></div>
        {/*<div id="networkContainer" ref={sectionRefs.networkContainer}><NetworkContainer /></div> */}
        {/* Add other sections with their respective refs and ids */}
      </div>
    </main>
  );
}

      


export default Interface;
  
import React, {createContext, useContext} from "react";

const RequestContext = createContext();

export function useRequest() {
    return useContext(RequestContext);
}

export const RequestProvider = ({children}) => {
  async function sendRequest({url, method = '', headers = {}, body = null, token = ''}) {
    const defaultHeaders = {
      'Authorization': `Bearer ${token}`,
    };
    const finalHeaders = {...defaultHeaders, ...headers};

    const response = await fetch(
      url, 
      {
        method,
        headers: finalHeaders,
        body: body ? JSON.stringify(body) : null
      }
    );
    return response.json();
  }
    
  const value = {sendRequest};
  
  return (
    <RequestContext.Provider value={value}>
      {children}
    </RequestContext.Provider>
  )
}


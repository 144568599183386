import React from "react";
import { Navigate, BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
import Menu from "./menu/menuRouting";
import {useAuth} from "./util/AuthProvider";
import { RequestProvider } from "./util/RequestProvider";
import Entry from "./entry/entry";


function App() {
  const {user} = useAuth();
  
  // <Route path="/signup" element={<SignUpPage />} />
  return (
    <RequestProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Entry/>} />
            <Route path="/menu/*" element={user ? <Menu/> : <Navigate to="/"/>} />
          </Routes>
        </Router>
      </RequestProvider>
  );
}

export default App;

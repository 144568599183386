import React, { useEffect, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import {useRequest} from '../../../util/RequestProvider';
import {useAuth} from '../../../util/AuthProvider';
import SpacebarButton from '../SpacebarButton/spacebarButton';

function FeasibilityContent({ventureId}) {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverContent, setPopoverContent] = useState('');
  const [popoverTitle, setPopoverTitle] = useState('');
  const [target, setTarget] = useState(null);
  const [content, setContent] = useState({
    Strengths: "",
    Weaknesses: "",
    Opportunities: "",
    Threats: "",
  });
  const {sendRequest} = useRequest();
  const {token} = useAuth();
  useEffect(() => {
    const getContent = async () => {
      let SWOTContent;
      sendRequest({
        url: 'https://api-mp6fvdmhza-uc.a.run.app/feasibility/getSWOT',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: { ventureId: ventureId},
        token: token
      }).then((response) => {
        SWOTContent = response;
        console.log(SWOTContent);
        setContent({
          Strengths: SWOTContent.strengths,
          Weaknesses: SWOTContent.weaknesses,
          Opportunities: SWOTContent.opportunities,
          Threats: SWOTContent.threats,
        });
      }).catch((error) => {
        console.error("Error while requesting SWOT content: ", error);
      });
      
    };
    getContent();
  }, [ventureId, token, sendRequest])
  

  const handleClick = (event, type) => {
    setTarget(event.target); 
    const display = Object.values(content[type]).join(". ") + ".";
    setPopoverContent(display);
    setPopoverTitle(event.target.textContent);
    setShowPopover(!showPopover);
  };

  return (
    <main className='feasibilitycontent'>
      <h1>Feasibility Analyses</h1>
      <h2>This section of Feasibility looks at analytical frameworks like SWOT and PEST to give you a better understanding of this venture's feasibility.</h2>
      <div className="buttonwrapperContent" >
          <SpacebarButton onClick={(e) => handleClick(e, 'Strengths')}>Strengths</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'Weaknesses')}>Weaknesses</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'Opportunities')}>Opportunities</SpacebarButton>
          <SpacebarButton onClick={(e) => handleClick(e, 'Threats')}>Threats</SpacebarButton>
        </div>
        <Overlay show={showPopover} target={target} placement="bottom">
        <Popover id="popover-contained">
          <Popover.Header as="h3">{popoverTitle}</Popover.Header>
          <Popover.Body>{popoverContent}</Popover.Body>
        </Popover>
        </Overlay>
    </main>
    );
  
}

export default FeasibilityContent;
